var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy1",attrs:{"id":"main"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"all1"},[_vm._m(5),_c('div',{staticClass:"all17"},[_vm._v(" Copyright © 周口拔刀网络科技有限公司 "),_c('span',{on:{"click":_vm.put}},[_vm._v("豫ICP备2023002840号-1")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy2"},[_c('div',{staticStyle:{"background":"rgb(22 22 23 / 30%)","width":"100%","height":"100%"}},[_c('div',{staticClass:"sy3"},[_c('div',{staticStyle:{"font-size":"38px","color":"#fff","align-self":"center"}},[_vm._v(" 虚拟充值 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy4"},[_c('span',[_vm._v(" 整合数字权益商品集成式服务应用，提供模块化虚拟商城搭建服务，帮助企业 "),_c('br'),_vm._v(" 进行上下游供采、运营链路。提供批量充采、API接入等数字产品交易平台的系统对接。"),_c('br'),_vm._v(" 为企业自助配置商品、流量变现提供技术、产品、服务、售后全套解决方案。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy6"},[_c('div',{staticClass:"sy7"},[_c('div',{staticClass:"sy8"},[_c('span',[_vm._v(" 服务优势 ")])]),_c('div',{staticClass:"sy10"},[_c('div',{staticClass:"sy9"},[_c('div',[_vm._v(" 品类齐全 ")]),_c('section',[_vm._v(" 整合虚拟数字产品，集成影视、卡券、话费、游戏点卡等众多虚拟行业内的销量领先类目。 ")])]),_c('div',{staticClass:"sy9"},[_c('div',[_vm._v(" 货源优质 ")]),_c('section',[_vm._v(" 我们为客户提供专属服务，享受更具竞争力的价格，充值快速稳定，成功率接近99.9%。 ")])]),_c('div',{staticClass:"sy9"},[_c('div',[_vm._v(" 支付整合 ")]),_c('section',[_vm._v(" 支持微信、支付宝、银联、快捷支付等主流移动支付网关，满足更多需求。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy7 sy11"},[_c('div',{staticStyle:{"margin-bottom":"37px"}},[_c('div',{staticClass:"sy13"},[_vm._v(" 业务优势 ")]),_c('div',{staticClass:"sy14"},[_vm._v(" 1.技术保障 安全可靠 ")]),_c('div',[_vm._v(" 为合作企业提供安全稳定的服务接口，实现数字产品即时性交易的一体化，"),_c('br'),_vm._v(" 提升交易效率以及系统的用户体验，合作双赢。 ")])]),_c('div',{staticClass:"sy15"},[_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v2.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 兼容")]),_c('div',[_vm._v(" 通过自身接口协议，无缝连接供采双方端口，保证供采双方顺畅交易")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v3.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 安全")]),_c('div',[_vm._v(" 云概念、分布式存储、多向加密，多维防护配置，保障GG防护效果")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v4.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 稳定")]),_c('div',[_vm._v("国内BGP多线路节点容灾技术，智能最优路径，秒级响应可靠稳定")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v5.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 智能")]),_c('div',[_vm._v("大数据辅助决策，人工智能并行干预，支撑百亿级年交易量，提升容错率")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sy11",staticStyle:{"background":"#F7F7F8","width":"100%","padding-top":"75px"}},[_c('div',{staticStyle:{"width":"1200px","margin":"0px auto"}},[_c('div',{staticStyle:{"margin-bottom":"37px"}},[_c('div',{staticClass:"sy14"},[_vm._v(" 2.服务保障 合作安心 ")]),_c('div',[_vm._v(" 7X24小时无休制服务，保证全平台供采双方交易安全稳定 ")])]),_c('div',{staticClass:"sy17"},[_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v6.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 安全防护")]),_c('div',[_vm._v(" 7×24小时安全团队专业防护")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v7.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 客服保障")]),_c('div',[_vm._v("7×24小时人工智能客服+专业人工客服团队保障售后服务")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v8.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 即时提现")]),_c('div',[_vm._v("7×24小时贴心财务服务，D+0即时提现服务，提升客户资金利用率")])]),_c('div',{staticClass:"sy16"},[_c('img',{attrs:{"src":require("../assets/v9.png"),"alt":"","srcset":""}}),_c('h2',[_vm._v(" 即时提现")]),_c('div',[_vm._v("7×24小时贴心财务服务，D+0即时提现服务，提升客户资金利用率")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"all2"},[_c('div',{staticClass:"all3"},[_c('div',[_vm._v(" 拔刀科技 ")]),_c('div',{staticClass:"all4"},[_vm._v(" 合作 ▪ 分享 ▪ 共赢 ")])]),_c('div',{staticClass:"all5"},[_c('div',{staticClass:"all6"},[_vm._v(" 周口拔刀网络科技有限公司 ")]),_c('div',{staticClass:"all4",staticStyle:{"display":"flex"}},[_c('div',[_c('i',{staticClass:"el-icon-office-building"})]),_c('div',[_vm._v(" 河南省郑州市金水区金水路305号1号楼2单元 "),_c('br'),_vm._v("25层西户 ")])]),_c('div',{staticClass:"all4"},[_c('i',{staticClass:"el-icon-phone-outline"}),_c('span',[_vm._v(" 15136250351 ")])])])])
}]

export { render, staticRenderFns }