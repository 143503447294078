<template>


    <div class="sy1" id="main">

        <div class="sy2">
            <div style="background: rgb(22 22 23 / 30%);width: 100%;height: 100%;">

                <div class="sy3">

                    <div style="font-size: 38px;color: #fff;align-self: center; ">
                        虚拟充值
                    </div>
                    <!-- <div>
                    数字权益商品集成式服务应用，提供模块化虚拟商 <br>
                    城搭建服务，帮助企业自助配置商品、定价 、UI<br>
                    模板及支付方式，快速创建属于自己的虚拟商城。
                </div> -->
                </div>
            </div>

        </div>

        <div class="sy4">
            <span>
                整合数字权益商品集成式服务应用，提供模块化虚拟商城搭建服务，帮助企业 <br>
                进行上下游供采、运营链路。提供批量充采、API接入等数字产品交易平台的系统对接。<br>
                为企业自助配置商品、流量变现提供技术、产品、服务、售后全套解决方案。
            </span>

        </div>

        <div class="sy6">

            <div class="sy7">
                <div class="sy8">
                    <span>
                        服务优势
                    </span>
                    <!-- <div style="margin-top: 10px;">
                        整合虚拟类商品上下游产业生态，从量级供应、技术服务、产品运营、营销推 <br> 广等维度为企业提供一站式解决方案
                    </div> -->
                </div>

                <div class="sy10">
                    <div class="sy9">
                        <div>
                            品类齐全
                        </div>
                        <section>
                            整合虚拟数字产品，集成影视、卡券、话费、游戏点卡等众多虚拟行业内的销量领先类目。
                        </section>
                    </div>
                    <div class="sy9">
                        <div>
                            货源优质
                        </div>
                        <section>
                            我们为客户提供专属服务，享受更具竞争力的价格，充值快速稳定，成功率接近99.9%。
                        </section>
                    </div>
                    <div class="sy9">
                        <div>
                            支付整合
                        </div>
                        <section>
                            支持微信、支付宝、银联、快捷支付等主流移动支付网关，满足更多需求。
                        </section>
                    </div>

                </div>


            </div>

        </div>

        <div class="sy7 sy11">
            <div class="" style="margin-bottom: 37px;">
                <div class="sy13">
                    业务优势
                </div>
                <div class="sy14">
                    1.技术保障 安全可靠
                </div>
                <div>
                    为合作企业提供安全稳定的服务接口，实现数字产品即时性交易的一体化，<br>
                    提升交易效率以及系统的用户体验，合作双赢。
                </div>
            </div>
            <div class="sy15">

                <div class="sy16"><img src="../assets/v2.png" alt="" srcset="">
                    <h2> 兼容</h2>
                    <div> 通过自身接口协议，无缝连接供采双方端口，保证供采双方顺畅交易</div>
                </div>
                <div class="sy16"><img src="../assets/v3.png" alt="" srcset="">
                    <h2> 安全</h2>
                    <div> 云概念、分布式存储、多向加密，多维防护配置，保障GG防护效果</div>
                </div>
                <div class="sy16"><img src="../assets/v4.png" alt="" srcset="">
                    <h2> 稳定</h2>
                    <div>国内BGP多线路节点容灾技术，智能最优路径，秒级响应可靠稳定</div>
                </div>
                <div class="sy16"><img src="../assets/v5.png" alt="" srcset="">
                    <h2> 智能</h2>
                    <div>大数据辅助决策，人工智能并行干预，支撑百亿级年交易量，提升容错率</div>
                </div>
            </div>
        </div>


        <div class="sy11" style="background: #F7F7F8;width: 100%;padding-top: 75px;">
            <div style="width: 1200px;margin: 0px auto;">

                <div class="" style="margin-bottom: 37px;">
                    <div class="sy14">
                        2.服务保障 合作安心
                    </div>
                    <div>
                        7X24小时无休制服务，保证全平台供采双方交易安全稳定
                    </div>
                </div>
                <div class="sy17">

                    <div class="sy16"><img src="../assets/v6.png" alt="" srcset="">
                        <h2> 安全防护</h2>
                        <div> 7×24小时安全团队专业防护</div>
                    </div>
                    <div class="sy16"><img src="../assets/v7.png" alt="" srcset="">
                        <h2> 客服保障</h2>
                        <div>7×24小时人工智能客服+专业人工客服团队保障售后服务</div>
                    </div>
                    <div class="sy16"><img src="../assets/v8.png" alt="" srcset="">
                        <h2> 即时提现</h2>
                        <div>7×24小时贴心财务服务，D+0即时提现服务，提升客户资金利用率</div>
                    </div>
                    <div class="sy16"><img src="../assets/v9.png" alt="" srcset="">
                        <h2> 即时提现</h2>
                        <div>7×24小时贴心财务服务，D+0即时提现服务，提升客户资金利用率</div>
                    </div>
                </div>
            </div>
        </div>



        <div class="all1">

            <div class="all2">

                <div class="all3">

                    <div>
                        拔刀科技
                    </div>

                    <div class="all4">
                        合作 ▪ 分享 ▪ 共赢
                    </div>
                </div>

                <div class="all5">
                    <div class="all6">
                        <!-- 周口拔刀网络科技有限公司 -->
                        周口拔刀网络科技有限公司
                    </div>
                    <div class="all4" style="display: flex;">
                        <div>
                            <i class="el-icon-office-building"></i>
                        </div>
                        <div>
                            河南省郑州市金水区金水路305号1号楼2单元 <br>25层西户
                        </div>
                    </div>
                    <div class="all4">
                        <i class="el-icon-phone-outline"></i>
                        <span>
                            15136250351
                        </span>
                    </div>
                </div>
            </div>

            <div class="all17">
                Copyright © 周口拔刀网络科技有限公司 <span @click="put">豫ICP备2023002840号-1</span>
            </div>
        </div>


    </div>


</template>
<script>

export default {
    name: 'introduce',
    components: {
    },

    data() {
        return {

        }
    },
    mounted() {
        document.getElementById("main").scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    },

    methods: {
        put() {
            window.open('https://beian.miit.gov.cn/')
        },
    }
}

</script>
<style lang="less" scoped>
.sy1 {
    width: 100%;
    height: 100%;
    // height: 3000px;

}

.sy2 {
    margin: 0 auto;
    // width: 1484px;
    height: 600px;
    background-image: url(../assets/v1.png);
    // background-repeat: repeat-x;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.sy3 {
    font-size: 22px;
    color: #FFFFFF;
    font-weight: 600;
    // padding-left: 41%;
    // padding-top: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    // align-content: center;

}

.sy4 {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 50px 260px;

    span {
        font-size: 22px;
        color: #000;
        letter-spacing: 1px;
        font-weight: 500;
    }
}



.sy6 {
    width: 100%;
    background: #F1F1F8;
    padding-bottom: 41px;
}

.sy7 {
    max-width: 1200px;
    margin: 0 auto;
}

.sy8 {
    text-align: center;
    color: #000000;
    padding-top: 70px;
    padding-bottom: 62px;

    >span {
        font-size: 36px;
        font-weight: 800;
    }

    >div {
        color: #8D8B8B;
        line-height: 26px;
    }
}

.sy10 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sy9 {
    text-align: center;
    // padding: 60px 40px;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    width: 26%;
    height: 396px;

    img {
        margin-top: 80px;
    }

    div {
        font-weight: 800;
        font-size: 27px;
        color: #000;
        margin-top: 50px;
    }

    section {
        letter-spacing: 1px;
        padding: 39px 43px;
        font-size: 18px
    }
}

.sy11 {
    text-align: center;
    padding-bottom: 47px;
}

.sy13 {
    color: #000000;
    font-weight: bold;
    font-size: 35px;
    margin-top: 90px;
    margin-bottom: 50px;
}

.sy14 {
    color: #000000;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
}

.sy15 {
    width: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
}

.sy16 {
    padding: 60px 40px;
    background: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    border: 1px solid #ECECEC;
    width: 260px;
    height: 396px;

    h2 {
        color: #000;
        margin: 28px 0px;
    }
}

.sy17 {

    width: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: space-around;
}

.all1 {
    // margin-top: 60px;
    background: #262431;
    height: 300px;
}

.all2 {
    width: 1000px;
    height: 86%;
    color: #F1F1F8;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all3 {
    font-size: 18px;
    font-weight: 600;

}

.all4 {
    margin-top: 16px;

    i {
        padding-right: 10px;
    }

    span {
        font-size: 15px;
    }
}

.all5 {}

.all6 {
    font-size: 20px;
    font-weight: 600;
}

.all17 {
    font-size: 13px;
    color: #f1f1f8;
    text-align: center;
    opacity: 0.6;

    span {
        cursor: pointer;
    }
}
</style>
